body {
  background-color: #FAF9F6;
  font-family: 'Lato', sans-serif;

}

.container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 120vh;
}
.title{
  position: absolute;
  left: 2%;
  top: 0%;
  color: #964B00;
  font-weight: bold;
  font-size: 6vh;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); Add the text shadow */
  font-family: 'Lato', sans-serif;
}


.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vh;
  height: 50vh;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8e2d1;
  border: 1px solid black;
  transition: background-color 0.3s;

}

.cell:hover {
  background-color: lightgrey;
}

#cell0 {
  border-radius: 5% 0 0 0;
 }
 #cell2{
  border-radius: 0 5% 0 0;
 }
 #cell6{ 
  border-radius: 0 0 0 5%;
 }
 #cell8 {
  border-radius: 0 0 5% 0;
 }
 .image-col {
  display: flex;
  flex-direction: column;
  height: 50vh;
  width: 15vh;
  position: absolute;
  top: 57%;
  left: calc(50% - 42vh);
  transform: translateY(-50%);
}

.image-row {
  display: flex;
  flex-direction: row;
  height: 15vh;
  width: 50vh;
  position: absolute;
  left: 50%;
  top: calc(57% - 42vh);
  transform: translateX(-50%);
}
.image {
  width: 100%;
  height: 33%;
  font-size: 4vh;
  left: 50%;
  top: 50%;
  align-items: center;
}
.textimage {
  margin-top: 50%;
  width: 100%;
  height: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: '150%';
   color: '#964B00';
   font-family: 'Impact, sans-serif';
}
.nba-icons {
  width: auto;
  height: auto;
}
.nba-icons-large {
  font-size: 30px; /* Adjust the size as desired */
}
.modal {
  position: fixed;
  top: 12%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e8e2d1;
  border: 1px solid black;
  width: 50vh;
  max-width: 80%;
  height: 45px;
  border-radius: 4px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  transition: background-color .3s;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* transition: transform 0.5s ease-in-out; 
  background-color: rgba(0, 0, 0, 0.5);  */
}

.guess {
  background-color: #e8e2d1;
  width: fill;
  height: 80%;
  margin: 3px;
  font-size: 20px; /* Set the desired font size */
  font-family: Arial, sans-serif; /* Set the desired font family */
  border: none;
  outline: none;

}

.suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #e8e2d1;
  border: 1px solid black;
  border-top: none;
  max-height: auto;
  overflow-y: 100%;
  font-family: Arial, sans-serif; /* Set the desired font family */
  font-size: 20px;
}

.suggestions ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.suggestions li {
  padding: 10px;
  cursor: pointer;
  margin-left: 2%;
  
}

.suggestions li:hover {
  background-color: #f2f2f2;
}

.rules {
  font-weight: bold;
  position: absolute;
  top: 5%;
  left: 95%;
}

#second-modal {
  width: 40vh;
  height: 60vh;
  background-color: #e8e2d1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5% 5% 5% 5%;
}
.rules-title {
  margin: 5%;
  font-family: 'Lato', sans-serif;
  font-weight: bold;

}
.rules-box{
  margin: 5%;
  font-size: 2vh;
  font-family: 'Lato', sans-serif;

}
#mvp {
display: 'flex';
justify-content: 'center';
align-items: 'center';
}

@media (max-width: 800px) {
  .title{
    left: 7%;
  }
  .grid {
    width: 55vw;
    height: 55vw;
    padding-top: 50%;
    top: 30%;
    left: 55%;
    transform: translate(-50%, -50%);
  }

  .image-col {
    height: 55vw;
    width: 16vw;
    top: 43%;
    left: 15%;
    transform: translate(-50%, -50%);
  }

  .image-row {
    height: 16svw;
    width: 55vw;
    top: 20%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
  .modal {
    position: fixed;
    top: 14%;
    left: 55%;
    transform: translate(-50%, -50%);
    background-color: #e8e2d1;
    border: 1px solid black;
    width: 30vh;
    max-width: 80%;
    height: 45px;
    border-radius: 4px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
    transition: transform .5s ease-in-out;
  }

  .rules {
    font-weight: bold;
    position: absolute;
    top: 5%;
    left: 85%;
  }

  .textimage {
    margin-top: 50%;
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: '100%';
     color: '#964B00';
     font-family: 'Impact, sans-serif';
  }
  
}